import React, { useEffect } from "react";
import HeroSection from "../components/HeroSection";
import About from "../components/AboutUs";
import Facts from "../components/Facts";
import ContactAlt from "../components/common/contactAlt";
import Footer from "../components/Footer";
import axios from "axios";
import configData from "../config.json"
import Officials from "../components/officials/officials";

function Home() {
  useEffect(() => {
    const fetchLinks = async () => {
      await axios
        .get(configData.links.api + "api/v1/Links/getLinks")
        .then((res) => {
          window.localStorage.setItem("ApiUrl", configData.links.api);
        });
    };
    fetchLinks();
  }, []);
  return (
    <>
      <main>
        <HeroSection />
        <About />
        <Facts />
      </main>
      <Officials/>
      <ContactAlt/>
      <Footer />
    </>
  );
}

export default Home;
