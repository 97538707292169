import React, { useState } from 'react';
import logo from "../../img/logo3.png";


const Step1 = ({ onNextStep }) => {
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [username, setUsername] = useState("");
  const [dob, setDob] = useState("");
  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(1); // Track current step

  // Personal Details Fields
  const [phoneNumber, setPhoneNumber] = useState("");
  const [alternativeMobile, setAlternativeMobile] = useState("");
  const [profession, setProfession] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [userType, setUserType] = useState("");
  const [paidStatus, setPaidStatus] = useState("");

    // Step 3 State
    const [userId, setUserId] = useState("");
    const [pin, setPin] = useState("");
    const [nameOnCard, setNameOnCard] = useState("");
    const [rankInUnit, setRankInUnit] = useState("");
    const [bloodGroup, setBloodGroup] = useState("");
    const [applicationType, setApplicationType] = useState("");

    // step 4 state
    const [documents, setDocuments] = useState(null);

  const handleFirstNameChange = (e) => setFirstName(e.target.value);
  const handleLastNameChange = (e) => setLastName(e.target.value);
  const handleMiddleNameChange = (e) => setMiddleName(e.target.value);
  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handleGenderChange = (e) => setGender(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const confirmhandlePasswordChange = (e) => setConfirmPassword(e.target.value);
  const handleDobChange = (e) => setDob(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
  const handleAlternativeMobileChange = (e) => setAlternativeMobile(e.target.value);
  const handleProfessionChange = (e) => setProfession(e.target.value);
  const handleHomeAddressChange = (e) => setHomeAddress(e.target.value);
  const handleUserTypeChange = (e) => setUserType(e.target.value);
  const handlePaidStatusChange = (e) => setPaidStatus(e.target.value);
  const handleUserIdChange = (e) => setUserId(e.target.value);
  const handlePinChange = (e) => setPin(e.target.value);
  const handleNameOnCardChange = (e) => setNameOnCard(e.target.value);
  const handleRankInUnitChange = (e) => setRankInUnit(e.target.value);
  const handleBloodGroupChange = (e) => setBloodGroup(e.target.value);
  const handleApplicationTypeChange = (e) => setApplicationType(e.target.value);
  
  const validateFields = () => {
    const newErrors = {};
    if (!firstName || firstName.length < 3) {
      newErrors.firstName = "First Name must be at least 3 characters long.";
    }
    if (!lastName || lastName.length < 3) {
      newErrors.lastName = "Last Name must be at least 3 characters long.";
    }
    if (!username || username.length < 3) {
      newErrors.username = "Username must be at least 3 characters long.";
    }
    if (!gender) {
      newErrors.gender = "Gender is required.";
    }
    if (!dob) {
      newErrors.dob = "Date of Birth is required.";
    }
    if (!password || password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long.";
    }
    if (password !== confirmpassword) {
      newErrors.confirmpassword = "Passwords do not match.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleContinue = () => {
    if (validateFields()) {
      setCurrentStep(currentStep + 1); // Move to next step
      // onNextStep();
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1); // Move to previous step
    }
  };

  const handleStepClick = (step) => {
    setCurrentStep(step); // Set active step when clicked
  };

  const stepClass = (step) => {
    return currentStep === step
      ? "bg-black text-white" // Active state for number
      : "bg-gray-300 text-gray-700"; // Inactive state for number
  };

  const stepTextClass = (step) => {
    return currentStep === step
      ? "text-black font-semibold" // Active state for text
      : "text-gray-700"; // Inactive state for text
  };

  const validateFiles = () => {
    if (!documents || documents.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        documents: "Please upload at least one document.",
      }));
      return false;
    }
    // Optionally, validate file types or size here
    return true;
  };
  

  

  return (
    <div className="justify-center items-center h-screen">
      <div className="w-full md:w-[700px] px-6 py-5 shadow-xl bg-white rounded-lg mt-5">
      
   
      </div>

    
    <div className="flex justify-center items-center h-screen">
      <div className="w-full md:w-[700px] px-6 py-5 shadow-xl bg-white rounded-lg mt-5">
        
      {/* Logo Section */}
      <div className="justify-center mb-5">
        <a href="/" className="flex justify-center">
          <img src={logo} alt="logo" className="w-[80px]" />
        </a>
      </div>
        {/* Centered Sign Up Title */}
        <h1 className="text-sm font-bold text-center mb-8">Sign Up</h1>

    {/* Page Indicator */}
    <div className="flex justify-center space-x-[5rem] mb-10">
      {[1, 2, 3, 4].map((step) => (
        <div
          key={step}
          className={`cursor-pointer flex flex-col items-center justify-center  hover:scale-110 transition duration-200`}
          onClick={() => handleStepClick(step)}
        >
          {/* Circle with number */}
          <div 
  className={`w-[15px] h-[15px] bg-black rounded-full flex items-center justify-center ${stepClass(step)}`} 
  style={{
    width: '30px', 
    height: '30px', 
    marginBottom: '8px', 
  }}
> 

            <div className="font-bold text-white text-[12px]">
              {step}
            </div>
          </div>
          {/* Step text below the circle */}
          <div className={`w-[100px] text-[8px] text-center ${stepTextClass(step)} `}>
            {step === 1
              ? 'User Information'
              : step === 2
              ? 'Personal Details'
              : step === 3
              ? 'Professional Info'
              : 'Upload Documents'}
          </div>
        </div>
      ))}
    </div>

        {/* Form Fields */}
        {currentStep === 1 && (
          <div>
            {/* User Information Fields */}
            <div className="grid grid-cols-2 gap-6 mb-6">
              <div className="w-full">
                <label className="block font-semibold text-gray-700 mb-2">First Name</label>
                {errors.firstName && <p className="text-red-500 text-sm">{errors.firstName}</p>}
                <input
                  type="text"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  placeholder="First Name"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  required
                />
              </div>

              <div className="w-full">
                <label className="block font-semibold text-gray-700 mb-2">Last Name</label>
                {errors.lastName && <p className="text-red-500 text-sm">{errors.lastName}</p>}
                <input
                  type="text"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={handleLastNameChange}
                  required
                />
              </div>

              <div className="w-full">
                <label className="block font-semibold text-gray-700 mb-2">Middle Name</label>
                {errors.middleName && <p className="text-red-500 text-sm">{errors.middleName}</p>}
                <input
                  type="text"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  placeholder="Middle Name"
                  value={middleName}
                  onChange={handleMiddleNameChange}
                  required
                />
              </div>

              <div className="w-full">
                <label className="block font-semibold text-gray-700 mb-2">Username</label>
                {errors.username && <p className="text-red-500 text-sm">{errors.username}</p>}
                <input
                  type="text"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  placeholder="Username"
                  value={username}
                  onChange={handleUsernameChange}
                  required
                />
              </div>

              <div className="w-full">
                <label className="block font-semibold text-gray-700 mb-2">Gender</label>
                {errors.gender && <p className="text-red-500 text-sm">{errors.gender}</p>}
                <select
                  className="w-full p-3 border border-gray-300 rounded-md"
                  value={gender}
                  onChange={handleGenderChange}
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="w-full">
                <label className="block font-semibold text-gray-700 mb-2">Date of Birth</label>
                {errors.dob && <p className="text-red-500 text-sm">{errors.dob}</p>}
                <input
                  type="date"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  value={dob}
                  onChange={handleDobChange}
                  required
                />
              </div>

              <div className="w-full">
                <label className="block font-semibold text-gray-700 mb-2">Password</label>
                {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
                <input
                  type="password"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
              </div>

              <div className="w-full">
                <label className="block font-semibold text-gray-700 mb-2">Confirm Password</label>
                {errors.confirmpassword && <p className="text-red-500 text-sm">{errors.confirmpassword}</p>}
                <input
                  type="password"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  placeholder="Confirm Password"
                  value={confirmpassword}
                  onChange={confirmhandlePasswordChange}
                  required
                />
              </div>
            </div>
          </div>
        )}

        {/* Personal Details Fields */}
      {currentStep === 2 && (
  <div>
    {/* Personal Details Form */}
    <div className="grid grid-cols-2 gap-6 mb-6">
      <div className="w-full">
        <label className="block font-semibold text-gray-700 mb-2">Email Address</label>
        <input
          type="email"
          className="w-full p-3 border border-gray-300 rounded-md"
          placeholder="Email Address"
          value={email}
          onChange={handleEmailChange}
          required
        />
      </div>

      <div className="w-full">
        <label className="block font-semibold text-gray-700 mb-2">Phone Number</label>
        <input
          type="tel"
          className="w-full p-3 border border-gray-300 rounded-md"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          required
        />
      </div>

      <div className="w-full">
        <label className="block font-semibold text-gray-700 mb-2">Alternative Mobile</label>
        <input
          type="tel"
          className="w-full p-3 border border-gray-300 rounded-md"
          placeholder="Alternative Mobile"
          value={alternativeMobile}
          onChange={handleAlternativeMobileChange}
          required
        />
      </div>

      <div className="w-full">
        <label className="block font-semibold text-gray-700 mb-2">Profession</label>
        <input
          type="text"
          className="w-full p-3 border border-gray-300 rounded-md"
          placeholder="Profession"
          value={profession}
          onChange={handleProfessionChange}
          required
        />
      </div>

      {/* Home Address takes the entire row */}
      <div className="w-full col-span-2">
        <label className="block font-semibold text-gray-700 mb-2">Home Address</label>
        <input
          type="text"
          className="w-full p-3 border border-gray-300 rounded-md"
          placeholder="Home Address"
          value={homeAddress}
          onChange={handleHomeAddressChange}
          required
        />
      </div>

      {/* User Type and Paid Status side by side with same style */}
      <div className="w-full">
        <label className="block font-semibold text-gray-700 mb-2">User Type</label>
        <select
          className="w-full p-3 border border-gray-300 rounded-md"
          value={userType}
          onChange={handleUserTypeChange}
          required
        >
          <option value="">Select User Type</option>
          <option value="Admin">Admin</option>
          <option value="User">User</option>
        </select>
      </div>

      <div className="w-full">
        <label className="block font-semibold text-gray-700 mb-2">Paid Status</label>
        <select
          className="w-full p-3 border border-gray-300 rounded-md"
          value={paidStatus}
          onChange={handlePaidStatusChange}
          required
        >
          <option value="">Select Paid Status</option>
          <option value="Paid">Paid</option>
          <option value="Unpaid">Unpaid</option>
        </select>
      </div>
    </div>
  </div>
)}
{currentStep === 3 && (
          <div>
            {/* Professional Information Form */}
            <div className="grid grid-cols-2 gap-6 mb-6">
              {/* Row 1: User ID */}
              <div className="w-full">
                <label className="block font-semibold text-gray-700 mb-2">User ID</label>
                <input
                  type="text"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  placeholder="User ID"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  required
                />
              </div>

              {/* Row 2: PIN */}
              <div className="w-full">
                <label className="block font-semibold text-gray-700 mb-2">PIN</label>
                <input
                  type="text"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  placeholder="PIN"
                  value={pin}
                  onChange={(e) => setPin(e.target.value)}
                  required
                />
              </div>

              {/* Row 3: Name on Card */}
              <div className="w-full col-span-2">
                <label className="block font-semibold text-gray-700 mb-2">Name on Card</label>
                <input
                  type="text"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  placeholder="Name on Card"
                  value={nameOnCard}
                  onChange={(e) => setNameOnCard(e.target.value)}
                  required
                />
              </div>

              {/* Row 4: Rank in Unit and Blood Group */}
              <div className="w-full">
                <label className="block font-semibold text-gray-700 mb-2">Rank in Unit</label>
                <input
                  type="text"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  placeholder="Rank in Unit"
                  value={rankInUnit}
                  onChange={(e) => setRankInUnit(e.target.value)}
                  required
                />
              </div>

              <div className="w-full">
                <label className="block font-semibold text-gray-700 mb-2">Blood Group</label>
                <select
                  className="w-full p-3 border border-gray-300 rounded-md"
                  value={bloodGroup}
                  onChange={(e) => setBloodGroup(e.target.value)}
                  required
                >
                  <option value="">Select Blood Group</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                </select>
              </div>

              {/* Row 5: Application Type */}
              <div className="w-full col-span-2">
                <label className="block font-semibold text-gray-700 mb-2">Application Type</label>
                <input
                  type="text"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  placeholder="Application Type"
                  value={applicationType}
                  onChange={(e) => setApplicationType(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
        )}
        
        {currentStep === 4 && (
  <div>
    {/* Document Upload Form */}
    <div className="flex flex-col mb-6">
      <label className="block font-semibold text-gray-700 mb-4 text-center">
        Upload Documents
      </label>

      {/* Grid layout for Passport Photo and Signature */}
      <div className="grid grid-cols-2 gap-6">
        {/* Passport Photo Upload */}
        <div className="flex flex-col items-center">
          <label className="font-semibold mb-2">Passport Photo</label>
          <div className="w-full h-[200px] border-2 border-dashed border-gray-300 rounded-lg flex flex-col justify-center items-center shadow-md">
            <p className="text-center text-gray-500 mb-2">Drag and Drop or Upload</p>
            <p className="text-xs text-center text-gray-400">Minimum file size: 2MB</p>
          </div>
          <p> suggested image dimensions: 500x500px</p>
        </div>

        {/* Signature Upload */}
        <div className="flex flex-col items-center">
          <label className="font-semibold mb-2">Signature</label>
          <div className="w-full h-[200px] border-2 border-dashed border-gray-300 rounded-lg flex flex-col justify-center items-center shadow-md">
            <p className="text-center text-gray-500 mb-2">Drag and Drop or Upload</p>
            <p className="text-xs text-center text-gray-400">Minimum file size: 2MB</p>
          </div>
          <p> suggested image dimensions: 240x240px</p>
        </div>
      </div>
    </div>
  </div>
)}



        {/* Buttons */}
        {currentStep < 4 ? (
  <button
    className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
    onClick={handleContinue}
  >
    Continue
  </button>
) : (
  <button
    className="px-6 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
    onClick={() => alert("Form submitted!")}
  >
    Submit
  </button>
)}

      </div>
    </div>
    </div>
  );
  
};

export default Step1;
