import React, { useState } from 'react';

import Step1 from './step1';
import Step2 from './step2';
import { Link } from "react-router-dom";


const Signup = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  return (
    <div className='md:flex items-center justify-center h-full w-full md:h-[100vh] md:w-[100vw]'>
      <div className='my-20 md:my-0'>
        
        {currentStep === 1 && <Step1 onNextStep={handleNextStep} />}
        {currentStep === 2 && <Step2 setCurrentStep={setCurrentStep} />}
        
      </div>
    </div>
  );
};

export default Signup;
