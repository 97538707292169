import React, { useEffect } from "react";
import Login from "../components/Login";
import axios from "axios";
import configData from "../config.json"

function LoginPage() {
  useEffect(() => {
    const fetchLinks = async () => {
      await axios
      .get(configData.links.api + "api/v1/Links/getLinks")
        .then((res) => {
          window.localStorage.setItem("ApiUrl", configData.links.api);
          console.log(configData.links.api)
        });
    };
    fetchLinks();
  });
  return (
    <main>
      <Login />
    </main>
  );
}

export default LoginPage;
